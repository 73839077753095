/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useApiRootUrl, usePluginSessionId } from 'web-component/hooks';

import type { IPluginDataRequest, IPluginDataResponse } from 'types/PluginData';

import { api } from './apiHelper';
import apiRoutes from './apiRoutes';

const DEBUG = false;
const getQueryKey = (keywords: string) => ['getSearch', keywords];

export const useGetSearchQueryManager = () => {
    const queryClient = useQueryClient();

    return {
        removeQuery: (keywords: string) =>
            queryClient.removeQueries({ queryKey: getQueryKey(keywords) }),
    };
};

export const useGetSearchQuery = (postData: IPluginDataRequest) => {
    const apiRootUrl = useApiRootUrl();
    const sessionId = usePluginSessionId();
    DEBUG && console.debug('useGetSearchQuery:apiRootUrl', apiRootUrl);
    DEBUG && console.debug('useGetSearchQuery:sessionId', sessionId);

    if (apiRootUrl === undefined) {
        throw new Error('apiRootUrl is required for useGetSearchQuery');
    }

    if (sessionId === undefined) {
        throw new Error('sessionId is required for useGetSearchQuery');
    }

    return useInfiniteQuery<IPluginDataResponse>({
        queryKey: getQueryKey(postData.keywords),
        queryFn: async ({ pageParam = undefined, signal }) => {
            const url = pageParam ? apiRoutes.postNextSearchPage : apiRoutes.postSearch;
            DEBUG && console.debug('useInfiniteQuery:url', url);
            return api.postJson<IPluginDataResponse>(
                sessionId,
                apiRootUrl,
                url,
                {
                    ...postData,
                    nextPageToken: pageParam,
                },
                signal,
            );
        },
        cacheTime: Number.POSITIVE_INFINITY,
        staleTime: Number.POSITIVE_INFINITY,
        enabled: postData.keywords.length >= 3,
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPage) => lastPage.nextPageToken,
    });
};
