/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import type { Config } from 'tailwindcss';
import colors from 'tailwindcss/colors';

import { extended_colors } from './src/packages/config-util/generated/tailwind.config.ext';

export const SCREEN_BREAKPOINTS = {
    SM: 'sm',
    MD: 'md',
    LG: 'lg',
} as const;

export type ScreenBreakpointType = (typeof SCREEN_BREAKPOINTS)[keyof typeof SCREEN_BREAKPOINTS];

const palette = {
    'siemens-green-light': '#AAB414',
    'siemens-green-dark': '#647D28',
    'siemens-stone-dark': '#3C464B',
    'siemens-blue-1': '#003750',
    'siemens-blue-4': '#004d73',
    'siemens-blue-5': '#00557d',
    'siemens-blue-6': '#005a82',
    'siemens-blue-7': '#005F87',
    'siemens-blue-10': '#197FA2',
    'siemens-blue-13': '#3296B9',
    'siemens-stone-12': '#879BAA',
    'siemens-stone-25': '#F5F7FA',
    'siemens-natural-blue-10': '#CDE6EB',
    'siemens-natural-blue-11': '#DAECF0',
    'siemens-pure-black': '#000000',
    'siemens-pl-black-4': '#1E1E1E',
    'siemens-pl-black-8': '#464646',
    'siemens-pl-black-12': '#6E6E6E',
    'siemens-pl-black-15': '#8C8C8C',
    'siemens-pl-black-21': '#C8C8C8',
    'siemens-pl-black-23': '#DCDCDC',
    'siemens-pl-black-25': '#F0F0F0',
    'siemens-snow': '#FFFFFF',
    ...colors,
};

const config = {
    content: ['./index.html', './src/**/*.{ts,tsx}'],
    theme: {
        colors: {
            ...palette,
        },
        fontFamily: {
            sans: ['Segoe UI', 'sans-serif'],
        },
        fontSize: {
            'icon-description': ['0.625rem', '1rem'],
            notation: ['0.75rem', '1rem'],
            h4: ['1.0625rem', '1.4375rem'],
            p: ['0.875rem', '1.1875rem'],
        },
        boxShadow: {
            dropdown: '0px 3px 6px 0px var(--palette-black--pure-black)',
            tooltip: '0px 3px 6px 0px var(--palette-black--pure-black)',
            drawer: '0px 4px 8px 0px var(--palette-black--pure-black)',
            paginator: '-3px 1px 3px 0px var(--palette-black--pure-black)',
        },
        borderRadius: {
            '2': '2px',
            '3': '3px',
            '4': '4px',
            '8': '8px',
            '12': '12px',
        },
        screens: {
            [SCREEN_BREAKPOINTS.SM]: { min: '0px', max: '639px' },
            // => @media (min-width: 0px and max-width: 639px) { ... }

            [SCREEN_BREAKPOINTS.MD]: { min: '640px', max: '767px' },
            // => @media (min-width: 640px and max-width: 767px) { ... }

            [SCREEN_BREAKPOINTS.LG]: { min: '768px', max: '999999px' },
            // => @media (min-width: 768px and max-width: 9999px) { ... }
        },
        extend: {
            colors: { ...extended_colors },
        },
    },
    safelist: [
        // REAMRKS: Use to investigate issues with generated utility classes
        // 'bg-global_frame-background_default' // 👈  This includes bg for particular desging token
        // {
        //     pattern: /bg-+/, // 👈  This includes bg of all colors and shades
        // },
    ],
} satisfies Config;

export default config;
