/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { useUserData } from 'context/UserContextProvider';
import React from 'react';
import {
    type IInitParameters,
    siemensAnalyticsEvents,
    siemensAnalyticsService,
} from 'services/SiemensAnalyticsService';
import { usePluginAnalyticsOptIns } from 'web-component/hooks';

import { environment, sanInternalUsage, sanProductKey } from 'const';

export const useSiemensAnalytics = () => {
    const userData = useUserData();
    const optInsOriginal = usePluginAnalyticsOptIns();

    // this should not happen, if opt-ins are not specified Plugin should be stuck in initialization
    if (
        optInsOriginal.productExcellenceProgram === undefined ||
        optInsOriginal.digitalProductExperience === undefined
    ) {
        throw new Error('All opt-ins for Analytics need to be explicitly specified');
    }

    // TS still thinks that opt-ins can be undefined so we need to have this
    const optIns = {
        digitalProductExperience: optInsOriginal.digitalProductExperience,
        productExcellenceProgram: optInsOriginal.productExcellenceProgram,
    };

    const isInitialized = React.useRef(false);
    const previousUserData = React.useRef(userData);
    const previousOptIns = React.useRef(optIns);

    // this should not happen, if user data changes, the whole Plugin should restart
    if (
        previousUserData.current !== undefined &&
        JSON.stringify(previousUserData.current) !== JSON.stringify(userData)
    ) {
        throw new Error('User data should not change after initialization');
    }

    // opt-ins change after initialization
    if (isInitialized.current) {
        if (optIns.digitalProductExperience !== previousOptIns.current.digitalProductExperience) {
            if (optIns.digitalProductExperience) {
                siemensAnalyticsService.optInDigitalProductExperience();
            } else {
                siemensAnalyticsService.optOutDigitalProductExperience();
            }
        }

        if (optIns.productExcellenceProgram !== previousOptIns.current.productExcellenceProgram) {
            if (optIns.productExcellenceProgram) {
                siemensAnalyticsService.optInProductExcellenceProgram();
            } else {
                siemensAnalyticsService.optOutProductExcellenceProgram();
            }
        }
    }

    // initialize only when user data arrived and it wasn't initialized before
    if (!isInitialized.current && userData !== undefined) {
        if (optIns.digitalProductExperience) {
            siemensAnalyticsService.optInDigitalProductExperience();
        } else {
            siemensAnalyticsService.optOutDigitalProductExperience();
        }

        if (optIns.productExcellenceProgram) {
            siemensAnalyticsService.optInProductExcellenceProgram();
        } else {
            siemensAnalyticsService.optOutProductExcellenceProgram();
        }

        try {
            const initParameters: IInitParameters = {
                customerId: userData.ecaId,
                userId: userData.userId,
                userEmail: userData.email,
                productKey: sanProductKey,
                internalUsage: sanInternalUsage,
                sessionTimeoutInSec: 60 * 60, // 1 hour
                productTier: userData.productTier,
                gatherPIIData: false,
                environment: environment,
            };

            siemensAnalyticsService.init(initParameters);
            isInitialized.current = true;

            siemensAnalyticsService.logEvent(siemensAnalyticsEvents.createInitializationEvent());
        } catch (e) {
            siemensAnalyticsService.endSession();
            isInitialized.current = false;

            console.error('Analytics initialization error, ending session', e);
        }
    }

    // save data from this run to compare on the next run
    previousOptIns.current = optIns;
    previousUserData.current = userData;

    // end session on unmount (when Plugin's state is restarted, and it's showing the initialization screen)
    React.useEffect(() => {
        return () => {
            if (!isInitialized.current) {
                return;
            }

            siemensAnalyticsService.endSession();
        };
    }, []);

    return {
        isInitialized: isInitialized.current,
        optIns: {
            ...optIns,
        },
    };
};
