/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { useQuery } from '@tanstack/react-query';
import { useApiRootUrl, usePluginSessionId } from 'web-component/hooks';

import { IExtractAnalyticsInfoResponse } from 'types/ExtractAnalyticsInfo';

import { api } from './apiHelper';
import apiRoutes from './apiRoutes';

export const useGetAnalyticsInfoQuery = () => {
    const apiRootUrl = useApiRootUrl();
    const sessionId = usePluginSessionId();

    return useQuery<IExtractAnalyticsInfoResponse>({
        queryKey: ['getExtractAnalyticsInfo'],
        queryFn: async ({ signal }) =>
            api.getJson<IExtractAnalyticsInfoResponse>(
                sessionId!,
                apiRootUrl!,
                apiRoutes.getExtractAnalyticsInfo,
                undefined,
                signal,
            ),
        refetchOnWindowFocus: false,
        enabled: sessionId !== undefined && apiRootUrl !== undefined,
        useErrorBoundary: true,
        cacheTime: Number.POSITIVE_INFINITY,
        staleTime: Number.POSITIVE_INFINITY,
    });
};
