/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { useQuery } from '@tanstack/react-query';
import { useApiRootUrl, usePluginSessionId } from 'web-component/hooks';

import { DownloadKeyResponseType, IInitCacheForDownloadRequest } from 'types/Download';

import { api } from './apiHelper';
import apiRoutes from './apiRoutes';

export const useGetDownloadKeyQuery = (supplierPartNumber: string, enabled: boolean) => {
    const apiRootUrl = useApiRootUrl();
    const sessionId = usePluginSessionId();

    if (apiRootUrl === undefined) {
        throw new Error('apiRootUrl is required for useGetDownloadKeyQuery');
    }

    if (sessionId === undefined) {
        throw new Error('sessionId is required for useGetDownloadKeyQuery');
    }

    return useQuery<DownloadKeyResponseType>({
        queryKey: ['getDownloadKey', supplierPartNumber],
        queryFn: async ({ signal }) =>
            api.getJson<DownloadKeyResponseType>(
                sessionId,
                apiRootUrl,
                apiRoutes.getDownloadKey,
                undefined,
                signal,
            ),
        enabled: enabled,
        cacheTime: Infinity,
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        retry: 0,
    });
};

export const useGetInitCacheForDownloadQuery = (
    postData: IInitCacheForDownloadRequest,
    enabled: boolean,
) => {
    const apiRootUrl = useApiRootUrl();
    const sessionId = usePluginSessionId();

    if (apiRootUrl === undefined) {
        throw new Error('apiRootUrl is required for useGetInitCacheForDownloadQuery');
    }

    if (sessionId === undefined) {
        throw new Error('sessionId is required for useGetInitCacheForDownloadQuery');
    }

    return useQuery({
        queryKey: ['getInitCacheForDownload', postData.partToPartition[0].partId],
        queryFn: async ({ signal }) => {
            return api.post(
                sessionId,
                apiRootUrl,
                apiRoutes.postInitCacheForDownload,
                postData,
                signal,
            );
        },
        enabled: !!postData.downloadKey && enabled,
        cacheTime: Infinity,
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        retry: 0,
    });
};
