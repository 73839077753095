/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SearchContextProvider } from 'SearchContextProvider';
import { UserContextProvider } from 'context/UserContextProvider';
import { AppErrorBoundary } from 'core/AppErrorBoundary';
import { AnalyticsRoot, InitializationRoot, ThemeRoot } from 'core/roots';
import React from 'react';

import { RequestDetailsDrawerContextProvider } from 'components/drawers/RequestDetailsDrawer';
import { SupplyChainDrawerContextProvider } from 'components/drawers/SupplyChainDrawer';
import { DrawerContextProvider } from 'components/molecules/Drawer';
import { Layout } from 'components/organisms/Layout';
import { SearchView } from 'components/templates/SearchView';
import { isDevelopment } from 'const';

const AppContexts = (props: React.PropsWithChildren) => {
    const queryClient = React.useRef<QueryClient>(new QueryClient());

    React.useEffect(() => {
        return () => {
            queryClient.current.cancelQueries();
        };
    }, []);

    return (
        <ThemeRoot>
            <AppErrorBoundary showReload={true}>
                <QueryClientProvider client={queryClient.current}>
                    <UserContextProvider>
                        <InitializationRoot>
                            {/* everything below will not render until initialization is complete */}
                            <AnalyticsRoot>
                                <DrawerContextProvider>
                                    <SearchContextProvider>
                                        <SupplyChainDrawerContextProvider>
                                            <RequestDetailsDrawerContextProvider>
                                                {props.children}
                                            </RequestDetailsDrawerContextProvider>
                                        </SupplyChainDrawerContextProvider>
                                    </SearchContextProvider>
                                </DrawerContextProvider>
                            </AnalyticsRoot>
                        </InitializationRoot>
                    </UserContextProvider>

                    {
                        /* isDevelopment necessary for tree-shaking */
                        isDevelopment && <ReactQueryDevtools initialIsOpen={false} />
                    }
                </QueryClientProvider>
            </AppErrorBoundary>
        </ThemeRoot>
    );
};

export const App = () => {
    return (
        <AppContexts>
            <Layout>
                <SearchView />
            </Layout>
        </AppContexts>
    );
};
