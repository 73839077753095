/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import type { ComponentProps } from 'react';

import type { PluginSourceType } from 'types/PluginData';

import { TooltipIcon } from '../../../assets/TooltipIcon';
import { Tooltip } from '../Tooltip';

interface ISourceAndDescriptionTooltipProps {
    source: PluginSourceType;
    description: string;
}

const testIds = {
    content: 'source-and-description-tooltip-content',
    icon: 'source-and-description-tooltip-icon',
    sourceContainer: 'source-and-description-tooltip-source-container',
    source: 'source-and-description-tooltip-source',
    descriptionContainer: 'source-and-description-tooltip-description-container',
    description: 'source-and-description-tooltip-description',
} as const;

export { testIds as SourceAndDescriptionTooltipTestIds };

export const SourceAndDescriptionTooltip = ({
    source,
    description,
    ...props
}: ISourceAndDescriptionTooltipProps &
    Omit<ComponentProps<typeof Tooltip>, 'content' | 'className'>) => (
    <Tooltip
        {...props}
        className='rounded-4 border-0 bg-background-mid px-2 py-1 shadow-tooltip'
        content={
            <div
                className='max-w-[152px] overflow-hidden text-notation text-text-default'
                data-testid={testIds.content}
            >
                {source !== 'None' && (
                    <div data-testid={testIds.sourceContainer}>
                        <h3 className='font-bold'>Data Source:</h3>
                        <p data-testid={testIds.source}>{source}</p>
                    </div>
                )}

                <div data-testid={testIds.descriptionContainer} className='md:hidden lg:hidden'>
                    <h3 className='mt-1 font-bold'>Description:</h3>
                    <p data-testid={testIds.description}>{description}</p>
                </div>
            </div>
        }
        placement='right'
    >
        <TooltipIcon data-testid={testIds.icon} className='cursor-pointer' />
    </Tooltip>
);
