/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { flexRender } from '@tanstack/react-table';
import { ReactTableDevtools } from '@tanstack/react-table-devtools';
import React from 'react';

import { EmptyMessage } from 'components/molecules/EmptyMessage';
import { ErrorMessage } from 'components/molecules/ErrorMessage';
import { FetchMessage } from 'components/molecules/FetchMessage';
import { StartMessage } from 'components/molecules/StartMessage';
import { isDevelopment } from 'const';

import { useSearchTable } from './useSearchTable';

const testIds = {
    content: 'search-view-content',
    table: 'search-view-table',
    tableHeader: 'search-view-table-header',
    tableRowOdd: 'search-view-table-row-odd',
    tableRowEven: 'search-view-table-row-even',
    fetchMessage: 'search-view-fetch-message',
    emptyMessage: 'search-view-empty-message',
    errorMessage: 'search-view-error-message',
    startMessage: 'search-view-start-message',
} as const;

export { testIds as SearchViewTestIds };

export const SearchView = () => {
    const { data, table, queryResult, paginationView } = useSearchTable();

    if (queryResult.isFetching) {
        return <FetchMessage data-testid={testIds.fetchMessage} className='h-[calc(100vh-58px)]' />;
    }

    if (queryResult.isError) {
        return (
            <ErrorMessage
                data-testid={testIds.errorMessage}
                className='h-[calc(100vh-58px)]'
                showReload={false}
            />
        );
    }

    if (queryResult.fetchStatus === 'idle' && queryResult.isFetched && data?.length === 0) {
        return <EmptyMessage data-testid={testIds.emptyMessage} className='h-[calc(100vh-58px)]' />;
    }

    if (queryResult.fetchStatus === 'idle' && data.length === 0) {
        return <StartMessage data-testid={testIds.startMessage} className='h-[calc(100vh-58px)]' />;
    }

    return (
        <div data-testid={testIds.content} className='bg-background-inverted'>
            <table
                data-testid={testIds.table}
                className='w-full overflow-hidden overflow-y-scroll border-table-border text-notation text-text-default'
            >
                <thead data-testid={testIds.tableHeader} className='sticky top-0 bg-table-header'>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <th
                                        data-testid={header.id}
                                        key={header.id}
                                        style={{
                                            width: header.column.columnDef.size,
                                            minWidth: header.column.columnDef.minSize,
                                            maxWidth: header.column.columnDef.maxSize,
                                        }}
                                        className={
                                            'border-l border-table-border px-2 py-1 text-left first:rounded-tl-3 first:border-none last:rounded-tr-3'
                                        }
                                    >
                                        {header.isPlaceholder ? null : (
                                            <>
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext(),
                                                )}
                                            </>
                                        )}
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map((row, index) => (
                        <React.Fragment key={row.id}>
                            <tr
                                data-testid={index % 2 ? testIds.tableRowEven : testIds.tableRowOdd}
                                className={`border-b border-table-border hover:bg-table-cell_background_hover ${
                                    index % 2
                                        ? 'bg-table-cell_background2'
                                        : 'bg-table-cell_background1'
                                }`}
                            >
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <td key={cell.id}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext(),
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
            {paginationView}

            {
                /* isDevelopment necessary for tree-shaking */
                isDevelopment && <ReactTableDevtools table={table} />
            }
        </div>
    );
};
