/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React from 'react';
import { formatCurrencyForSupplyChain } from 'utils/currencyUtil';

import { Tooltip } from 'components/molecules/Tooltip';
import type { IPriceBreakdown } from 'types/DistributionChainInfo';
import type { KeyedType } from 'types/utility/Keyed';

import { TooltipIcon } from '../../../assets/TooltipIcon';

const testIds = {
    main: 'supply-chain-table-view-price-breakdown-main',
    large: 'supply-chain-table-view-price-breakdown-large',
    largeItem: 'supply-chain-table-view-price-breakdown-large-item',
    largeSeeMore: 'supply-chain-table-view-price-breakdown-large-see-more',
    small: 'supply-chain-table-view-price-breakdown-small',
    smallFirstItem: 'supply-chain-table-view-price-breakdown-small-first-item',
    smallTooltipIcon: 'supply-chain-table-view-price-breakdown-small-tooltip-icon',
    smallTooltipContent: 'supply-chain-table-view-price-breakdown-small-tooltip-conent',
    smallTooltipItem: 'supply-chain-table-view-price-breakdown-small-tooltip-item',
    smallTooltipItemQuantity: 'supply-chain-table-view-price-breakdown-small-tooltip-item-quantity',
    smallTooltipItemValue: 'supply-chain-table-view-price-breakdown-small-tooltip-item-value',
    tooltip: 'supply-chain-table-view-price-breakdown-tooltip',
};

export { testIds as SupplyChainTableViewPriceBreakdownsTestIds };

interface IPriceBreakdownsProps {
    data: Array<KeyedType<IPriceBreakdown>>;
}

export const PriceBreakdowns = ({ data }: IPriceBreakdownsProps) => {
    const [seeMore, setSeeMore] = React.useState(false);

    return (
        <div data-testid={testIds.main}>
            <div data-testid={testIds.large} className='sm:hidden md:hidden'>
                {data.map((pb, i) => (
                    <div
                        key={pb.key}
                        data-testid={testIds.largeItem}
                        className={`${i >= 3 && !seeMore ? 'hidden' : ''} text-notation`}
                    >
                        {pb.quantity} - {formatCurrencyForSupplyChain(pb.currency, pb.value)}
                    </div>
                ))}

                {data.length > 3 && !seeMore && (
                    <div
                        data-testid={testIds.largeSeeMore}
                        className='mt-[1px] cursor-pointer text-notation font-semibold text-text-link'
                        onClick={() => setSeeMore(true)}
                    >
                        See More
                    </div>
                )}
            </div>

            {data.length > 0 && (
                <div data-testid={testIds.small} className='flex lg:hidden'>
                    <div data-testid={testIds.smallFirstItem} className='text-notation'>
                        {data[0].quantity} -{' '}
                        {formatCurrencyForSupplyChain(data[0].currency, data[0].value)}
                    </div>

                    {data.length > 1 && (
                        <div className='ml-auto'>
                            <Tooltip
                                data-testid={testIds.tooltip}
                                className='w-[120px] rounded-4 bg-background-mid p-0 font-normal text-text-default shadow-tooltip'
                                content={
                                    <div data-testid={testIds.smallTooltipContent} className='p-2'>
                                        <span className='text-notation font-semibold'>Price:</span>

                                        {data.map((pb) => (
                                            <div
                                                data-testid={testIds.smallTooltipItem}
                                                key={pb.key}
                                                className='mt-1 flex text-notation'
                                            >
                                                <div data-testid={testIds.smallTooltipItemQuantity}>
                                                    {pb.quantity}
                                                </div>
                                                <div
                                                    data-testid={testIds.smallTooltipItemValue}
                                                    className='ml-auto'
                                                >
                                                    {formatCurrencyForSupplyChain(
                                                        pb.currency,
                                                        pb.value,
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                }
                                placement='left'
                            >
                                <TooltipIcon data-testid={testIds.smallTooltipIcon} />
                            </Tooltip>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
