/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */

export const getUrl = (baseUrl: string, url: string) => `${baseUrl}${url}`;

const headers = {
    'content-type': 'application/json',
};

const DEBUG = false;

export const api = {
    getJson: async <T>(
        sessionId: string,
        baseUrl: string,
        url: string,
        params?: URLSearchParams,
        signal?: AbortSignal,
    ) => {
        if (typeof params === 'object') {
            const urlObj = new URL(url);
            urlObj.search = new URLSearchParams(params).toString();
        }

        const response = await fetch(getUrl(baseUrl, url), {
            method: 'GET',
            headers: {
                ...headers,
                'session-id': sessionId,
            },
            credentials: 'include',
            signal,
        });

        if (!response.ok) {
            DEBUG && console.error('API - getJson request error', response.statusText);
            throw new Error(response.statusText);
        }

        // noinspection ES6MissingAwait
        return response.json() as Promise<T>;
    },
    post: async (
        sessionId: string,
        baseUrl: string,
        url: string,
        data: unknown,
        signal?: AbortSignal,
    ) => {
        const response = await fetch(getUrl(baseUrl, url), {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                ...headers,
                'session-id': sessionId,
            },
            credentials: 'include',
            signal,
        });

        if (!response.ok) {
            DEBUG && console.error('API - post request error', response.statusText);
            throw new Error(response.statusText);
        }

        return response.text();
    },
    postJson: async <T>(
        sessionId: string,
        baseUrl: string,
        url: string,
        data: unknown,
        signal?: AbortSignal,
    ) => {
        const fullUrl = getUrl(baseUrl, url);
        DEBUG && console.debug('postJson fullUrl:', { fullUrl });
        const body = JSON.stringify(data);
        DEBUG && console.debug('postJson body:', { body });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const testCookie = (window as any).testCookie; // TODO: temporary solution till new order of authorization is provided

        const composedHeaders = testCookie
            ? {
                  ...headers,
                  'session-id': sessionId,
                  cookie: testCookie, // Manually set the Cookie header
              }
            : {
                  ...headers,
                  'session-id': sessionId,
              };

        DEBUG && console.debug('postJson composedHeaders:', { composedHeaders });
        const response = await fetch(fullUrl, {
            method: 'POST',
            body: body,
            headers: composedHeaders,
            credentials: 'include',
            signal,
        });
        DEBUG && console.debug('postJson response:', JSON.stringify(response));
        if (!response.ok) {
            console.error('API - postJson request error:', response.status, response.statusText);
            throw new Error(response.status.toString());
        }

        // noinspection ES6MissingAwait
        return response.json() as Promise<T>;
    },
};
