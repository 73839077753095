/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React from 'react';

import type { RegionNameType, ThemeNameType } from 'types';

interface IPluginContextProps {
    theme: ThemeNameType;
    sessionId?: string;
    region?: RegionNameType;
    analyticsDigitalProductExperience?: boolean;
    analyticsProductExcellenceProgram?: boolean;
    rootRef: React.RefObject<HTMLDivElement>;
}

export interface IPluginContext extends IPluginContextProps {}

export const PluginContext = React.createContext<IPluginContext>({} as IPluginContext);

export const PluginContextProvider = (props: React.PropsWithChildren<IPluginContextProps>) => {
    return <PluginContext.Provider value={{ ...props }}>{props.children}</PluginContext.Provider>;
};
