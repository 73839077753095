/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import type React from 'react';
import { siemensAnalyticsEvents, siemensAnalyticsService } from 'services/SiemensAnalyticsService';

import { AccentedButton } from 'components/atoms/Button';
import { PartInfoTooltip } from 'components/organisms/SupplyChainTableView/PartInfoTooltip';
import { PriceBreakdowns } from 'components/organisms/SupplyChainTableView/PriceBreakdowns';
import {
    type IViewSettings,
    ViewSettingsTooltip,
} from 'components/organisms/SupplyChainTableView/ViewSettingsTooltip';
import type { KeyedDistributionChainInfoType } from 'types/DistributionChainInfo';

const testIds = {
    main: 'supply-chain-table-view-main',
    logo: 'supply-chain-table-view-logo',
    authorizedStatus: 'supply-chain-table-view-authorized-status',
    backToTop: 'supply-chain-table-view-back-to-top',

    table: 'supply-chain-table-view-table',
    header: 'supply-chain-table-view-header',
    headerPartHashDescription: 'supply-chain-table-view-header-part-hash-description',
    headerManufacturer: 'supply-chain-table-view-header-manufacturer',
    headerDescription: 'supply-chain-table-view-header-description',
    headerStock: 'supply-chain-table-view-header-stock',
    headerPrice: 'supply-chain-table-view-header-price',
    headerBuy: 'supply-chain-table-view-header-buy',

    row: 'supply-chain-table-view-row',

    cellPartHashDescription: 'supply-chain-table-view-cell-part-hash-description',
    cellManufacturer: 'supply-chain-table-view-cell-manufacturer',
    cellDescription: 'supply-chain-table-view-cell-description',
    cellStock: 'supply-chain-table-view-cell-stock',
    cellPrice: 'supply-chain-table-view-cell-price',
    cellBuy: 'supply-chain-table-view-cell-buy',

    manufacturerPartNumber: 'supply-chain-table-view-manufacturer-part-number',
    distributorPartNumber: 'supply-chain-table-view-distributor-part-number',
    manufacturerName: 'supply-chain-table-view-manufacturer-name',
    description: 'supply-chain-table-view-description',
    minimumOrderQuantity: 'supply-chain-table-view-minimum-order-quantity',
    leadTime: 'supply-chain-table-view-lead-time',
    containerType: 'supply-chain-table-view-container-type',
    stock: 'supply-chain-table-view-stock',
    buyNowButton: 'supply-chain-table-view-buy-now-button',
};

export { testIds as SupplyChainTableViewTestIds };

interface ISupplyChainTableViewProps {
    data: KeyedDistributionChainInfoType;
    drawerDetailsContainerRef: React.RefObject<HTMLDivElement>;
    viewSettings: IViewSettings;
    setViewSettings: React.Dispatch<React.SetStateAction<IViewSettings>>;
}

export const SupplyChainTableView = ({
    data,
    drawerDetailsContainerRef,
    viewSettings,
    setViewSettings,
    ...props
}: ISupplyChainTableViewProps &
    Omit<React.HTMLAttributes<HTMLDivElement>, 'children' | 'classname'>) => {
    function handleBuyNowCLick(buyNowUrl: string) {
        siemensAnalyticsService.logEvent(siemensAnalyticsEvents.createFindChipsBuyNowEvent());
        window.open(buyNowUrl);
    }

    return (
        <div data-testid={testIds.main} {...props} className='px-2 py-4 text-text-default'>
            <div className='flex items-center py-2'>
                {data.distributor.logoUrl !== undefined && (
                    <img
                        data-testid={testIds.logo}
                        src={data.distributor.logoUrl}
                        alt={data.distributor.name}
                        className='max-w-[100px] object-scale-down'
                    />
                )}

                {data.distributor.authorizedStatus && (
                    <div data-testid={testIds.authorizedStatus} className='ml-2 text-notation'>
                        Authorized Distributor
                    </div>
                )}

                <button
                    type='button'
                    data-testid={testIds.backToTop}
                    className='ml-auto text-notation font-semibold text-text-link'
                    onClick={() =>
                        drawerDetailsContainerRef.current?.scroll({ top: 0, behavior: 'smooth' })
                    }
                >
                    Back to top
                </button>
            </div>

            <table
                data-testid={testIds.table}
                className='border-1 w-full table-fixed border-separate border-spacing-0 rounded-3 border border-table-border'
            >
                <thead
                    data-testid={testIds.header}
                    className='bg-table-header text-notation font-bold'
                >
                    <tr className='[&>th:last-child]:border-r-0 [&>th]:border-r [&>th]:border-table-border [&>th]:px-2 [&>th]:py-1'>
                        <th
                            data-testid={testIds.headerPartHashDescription}
                            className='break-words rounded-tl-3 sm:w-auto md:w-auto lg:w-[142px]'
                        >
                            Part #/
                            <wbr />
                            Description
                        </th>
                        <th
                            data-testid={testIds.headerManufacturer}
                            className='sm:hidden md:hidden lg:w-[142px]'
                        >
                            Manufacturer
                        </th>
                        <th
                            data-testid={testIds.headerDescription}
                            className='sm:hidden md:hidden lg:w-auto'
                        >
                            Description
                        </th>
                        <th
                            data-testid={testIds.headerStock}
                            className='sm:w-[80px] md:w-[80px] lg:w-[96px]'
                        >
                            Stock
                        </th>
                        <th
                            data-testid={testIds.headerPrice}
                            className='sm:w-[128px] md:w-[128px] lg:w-[112px]'
                        >
                            Price
                        </th>
                        <th data-testid={testIds.headerBuy} className='w-[74px] rounded-tr-3'>
                            <div className='flex'>
                                <div>Buy</div>
                                <div className='ml-auto'>
                                    <ViewSettingsTooltip
                                        viewSettings={viewSettings}
                                        setViewSettings={setViewSettings}
                                    />
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    {data.parts.map((p, i) => {
                        const tableBandingStyle =
                            viewSettings.tableBanding && i % 2 !== 0
                                ? 'bg-table-cell_background2'
                                : '';

                        const rowBorderStyle = viewSettings.rowBorder
                            ? '[&>td]:border-b [&:last-child>td]:border-b-0'
                            : '';

                        const gridStyle = viewSettings.grid
                            ? '[&>td]:border-r [&>td:last-child]:border-r-0'
                            : '';

                        return (
                            <tr
                                key={p.key}
                                data-testid={testIds.row}
                                className={
                                    /* prettier-ignore */
                                    /* eslint-disable prettier/prettier */
                                    `lg:[&>td:not(:last-child)]:p-2 ` +
                                    `md:[&>td:not(:last-child)]:px-1 md:[&>td:not(:last-child)]:py-2 ` +
                                    `sm:[&>td:not(:last-child)]:px-1 sm:[&>td:not(:last-child)]:py-2 ` +
                                    `[&>td]:border-table-border ${tableBandingStyle} ${rowBorderStyle} ${gridStyle}`.trim()
                                    /* eslint-enable prettier/prettier */
                                }
                            >
                                <td
                                    data-testid={testIds.cellPartHashDescription}
                                    className='align-top'
                                >
                                    <div className='flex items-start'>
                                        <div className='text-notation'>
                                            <span
                                                data-testid={testIds.manufacturerPartNumber}
                                                className='break-all font-semibold text-text-link'
                                            >
                                                {p.manufacturerPartNumber}
                                            </span>

                                            {p.distributorPartNumber !== undefined && (
                                                <div className='sm:hidden md:ml-2 md:inline'>
                                                    <span className='font-semibold'>DISTI#:</span>{' '}
                                                    <span
                                                        data-testid={testIds.distributorPartNumber}
                                                        className='break-all'
                                                    >
                                                        {p.distributorPartNumber}
                                                    </span>
                                                </div>
                                            )}
                                        </div>

                                        <PartInfoTooltip data={p} />
                                    </div>
                                </td>

                                <td
                                    data-testid={testIds.cellManufacturer}
                                    className='align-top text-notation sm:hidden md:hidden'
                                >
                                    <span data-testid={testIds.manufacturerName}>
                                        {p.manufacturerName}
                                    </span>
                                </td>

                                <td
                                    data-testid={testIds.cellDescription}
                                    className='align-top text-notation sm:hidden md:hidden'
                                >
                                    {p.description !== undefined && (
                                        <div data-testid={testIds.description}>{p.description}</div>
                                    )}

                                    {p.minimumOrderQuantity !== undefined && (
                                        <div>
                                            Min Qty:{' '}
                                            <span
                                                data-testid={testIds.minimumOrderQuantity}
                                                className='font-semibold text-text-positive'
                                            >
                                                {p.minimumOrderQuantity}
                                            </span>
                                        </div>
                                    )}

                                    {p.leadTime !== undefined && (
                                        <div>
                                            Lead time:{' '}
                                            <span
                                                data-testid={testIds.leadTime}
                                                className='font-semibold text-text-positive'
                                            >
                                                {p.leadTime}
                                            </span>
                                        </div>
                                    )}

                                    {p.containerType !== undefined && (
                                        <div>
                                            Lead time:{' '}
                                            <span
                                                data-testid={testIds.containerType}
                                                className='font-semibold text-text-positive'
                                            >
                                                {p.containerType}
                                            </span>
                                        </div>
                                    )}
                                </td>

                                <td
                                    data-testid={testIds.cellStock}
                                    className='align-top text-notation'
                                >
                                    <span data-testid={testIds.stock}>{p.stock}</span>
                                </td>

                                <td data-testid={testIds.cellPrice} className='align-top'>
                                    <PriceBreakdowns data={p.priceBreakdown} />
                                </td>

                                <td data-testid={testIds.cellBuy} className='p-0 py-2'>
                                    {p.buyNowUrl !== undefined && (
                                        <AccentedButton
                                            data-testid={testIds.buyNowButton}
                                            className='mx-auto'
                                            onClick={() => handleBuyNowCLick(p.buyNowUrl!)}
                                        >
                                            Buy Now
                                        </AccentedButton>
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};
