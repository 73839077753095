/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { useUserData } from 'context/UserContextProvider';
import React from 'react';
import { usePluginRegion, usePluginSessionId } from 'web-component/hooks';
import { usePluginAnalyticsOptIns } from 'web-component/hooks/usePluginAnalyticsOptIns';

import { InitializationMessage } from 'components/molecules/InitializationMessage';

const testIds = {
    message: 'initialization-root-message',
    content: 'initialization-root-content',
};

export { testIds as InitializationRootTestIds };

export const InitializationRoot = ({ children }: React.PropsWithChildren) => {
    const sessionId = usePluginSessionId();
    const region = usePluginRegion();
    const analyticsOptIns = usePluginAnalyticsOptIns();

    const userData = useUserData();

    if (
        sessionId === undefined ||
        region === undefined ||
        userData === undefined ||
        analyticsOptIns.digitalProductExperience === undefined ||
        analyticsOptIns.productExcellenceProgram === undefined
    ) {
        return <InitializationMessage data-testid={testIds.message} className='h-screen' />;
    }

    return <div data-testid={testIds.content}>{children}</div>;
};
