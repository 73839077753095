/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import type { ComponentProps } from 'react';

import { ErrorIcon, ResetIcon } from 'assets';
import { AccentedButton } from 'components/atoms/Button';
import { Message } from 'components/atoms/Message';

interface ErrorMessageProps extends Omit<ComponentProps<typeof Message>, 'children'> {
    showReload: boolean;
}
const testIds = {
    errorIcon: 'error-message-error-icon',
    contentArea: 'error-message-content-area',
} as const;

export { testIds as ErrorMessageTestIds };
export const ErrorMessage = ({ showReload, ...props }: ErrorMessageProps) => {
    const handleReload = () => window.location.reload();

    return (
        <Message {...props}>
            <div
                className='flex h-full w-96 flex-col items-center justify-center text-text-hint_text_empty_screen'
                data-testid={testIds.contentArea}
            >
                <ErrorIcon className='mb-1' data-testid={testIds.errorIcon} />
                <h2 className='mt-2 text-h4 font-semibold'>Oops! Something went wrong...</h2>
                <p className='mt-0.5 text-p font-normal'>
                    {showReload
                        ? 'Use the button below to reload data. If this does not change anything, please try re-opening in a few minutes.'
                        : 'Reload the data or try again in a few minutes.'}
                </p>
                {showReload && (
                    <AccentedButton
                        onClick={handleReload}
                        className='absolute bottom-8 right-8 flex items-center justify-center px-4 py-2'
                    >
                        <ResetIcon className='mr-2 text-white' /> Reload
                    </AccentedButton>
                )}
            </div>
        </Message>
    );
};
